import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useContext } from "react";
import { Button } from "reactstrap";
import { PermissionsModalContext } from "../../../contexts/PermissionModalContext";
import { useTranslation } from "../../../contexts/TranslationContext";

export function PermissionsModal() {
    const {
        isPermissionModalOpen,
        closePermissionsModal,
    } = useContext(PermissionsModalContext);
    const { t } = useTranslation();

    return (
        <Dialog
            open={isPermissionModalOpen}
            onClose={closePermissionsModal}
        >
            <DialogTitle>
                {t('Insufficient Permissions')}
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                {t('You do not have the necessary permissions to modify this setting')}
                <br />
                {t('Please contact your administrator')}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={closePermissionsModal} autoFocus variant="contained">
                {t('Close')}
            </Button>
            </DialogActions>
        </Dialog>
    );
}
