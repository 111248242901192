import { createContext, ReactNode, useContext, useState } from 'react';
import { PermissionsModal } from '../views/components/settings/PermissionsModal';

type PermissionsModalContext = {
    isPermissionModalOpen: boolean;
    openPermissionsModal: () => void;
    closePermissionsModal: () => void;
};

export const PermissionsModalContext = createContext<PermissionsModalContext>({
    isPermissionModalOpen: false,
    openPermissionsModal: () => {},
    closePermissionsModal: () => {},
});

export function PermissionsModalContextProvider ({ children }: { children: ReactNode }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    function openPermissionsModal() {
        setIsModalOpen(true);
    }

    function closePermissionsModal() {
        setIsModalOpen(false);
    }

    const contextValue = {
        isPermissionModalOpen: isModalOpen,
        openPermissionsModal,
        closePermissionsModal,
    }

    return (
        <PermissionsModalContext.Provider value={contextValue}>
            {children}
            <PermissionsModal />
        </PermissionsModalContext.Provider>
    );
};

export function usePermissionsModalContext() {
    return useContext(PermissionsModalContext);
}
