import { StyleSheet } from 'aphrodite';

export const SettingsPageStyles = StyleSheet.create({
	main_container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		height: '90vh',
		overflow: 'hidden',
	},
	menu: {
		marginTop: '1rem',
		backgroundColor: "white",
		border: '1px solid lightgrey',
		borderRadius: 10,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		maxHeight: 'max-content',
		minWidth: 'max-content',
		marginLeft: '1rem'
	},
	menu_title: {
		fontSize: '1.4rem',
		fontWeight: 600,
		padding: '0.5rem',
		margin: 0,
		backgroundColor: '#f1f1f1'
	},
	menu_link: {
		borderTop: '1px solid lightgrey',
		overflow: 'hidden',
		padding: '0.5rem',
		fontSize: '1.1rem',
		fontWeight: 500,
	},
	link: {
		color: "#000099",
		textDecoration: 'underline',
	},
	settings_cards_container: {
		marginTop: '1rem',
		marginLeft: '1rem',
		marginRight: '1rem',
		marginBottom: '1rem',
		overflowY: 'auto'
	},
	settings_card: {
		width: '100%',
		backgroundColor: 'white',
		borderRadius: 10,
		border: '1px solid lightgrey',
		overflow: 'hidden',
		marginBottom: '1rem',
	},
	settings_card_body: {
		width: '100%',
		padding: '1rem',
	},
	settings_card_sections_container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '95%',
	},
	settings_card_section_container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: "flex-start",
		alignItems: 'baseline',
		marginRight: '2rem',
		width: '50%',
	},
	card_title: {
		fontSize: '1.4rem',
		fontWeight: 600,
		margin: 0,
		backgroundColor: '#f1f1f1',
		padding: '0.5rem 1rem',
		borderBottom: '1px solid lightgrey',
	},
	card_subtitle: {
		fontSize: '1.2rem',
		marginBottom: '1rem',
		color: '#000099',
		fontWeight: 600
	},
	card_input: {
		borderRadius: 5,
		border: '1px solid lightgrey',
		padding: '0.2rem 0.5rem',
		minWidth: '20rem',
		marginRight: '0.2rem',
		marginBottom: '0.5rem'
	},
	card_input_label: {
		fontSize: '1rem',
		marginBottom: '0.3rem',
	},
	disclaimer: {
		fontSize: '0.8rem',
		color: 'grey',
		margin: '0.5rem 0'
	},
	error: {
		fontSize: '0.9rem',
		color: 'red',
		margin: '-0.5rem 0 0.5rem',
	},
	label_error: {
		fontSize: '0.9rem',
		color: 'red',
		margin: '-0.5rem 0 0.5rem',
		maxWidth: '50%'
	},
	delete_model_style: {
		width: '300px',
		height: '300px',
		position: 'absolute',
		left: ' 50%',
		top: '50%',
		marginLeft: '-150px',
		marginTop: '-150px',
	},
	delete_model_header: {
		display: 'flex',
		justifyContent: 'center'
	},
	cancel_button_style: {
		backgroundColor: "#B4B4B4",
		borderColor: "#B4B4B4",
		width: '100%',
		fontWeight: 500,
	},
	confirm_delete_button_style: {
		backgroundColor: "#c4120c",
		borderColor: "#c4120c",
		width: '100%',
		fontWeight: 500,
	},
	disabled_input_wrapper: {
		cursor: 'not-allowed',
		display: 'inline-block',
	},
	disabled_input: {
		pointerEvents: 'none',
	},
})
