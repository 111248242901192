

//  @ts-ignore 
import low_battery_icon  from "../../assets/1.svg";
// @ts-ignore 
import high_warning_icon from "../../assets/2.svg";
// @ts-ignore 
import all_devices_icon from "../../assets/3.svg";
//  @ts-ignore
import high_battery_icon from "../../assets/4.svg";
//  @ts-ignore 
import missed_message_icon from "../../assets/5.svg";
//  @ts-ignore 
import weathermode_icon from "../../assets/6.svg";
//  @ts-ignore 
import fill_percentage_icon from "../../assets/7.svg";
/* eslint-disable no-useless-escape */
import '../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../styles/shared_styles.css';
import {
  useRecoilValue,
} from 'recoil';
import { deviceListStateAtom } from "../../models/atoms/device_atoms";
import { calculateAverageBattery, calculateAverageFillPercentage, calculateMissingMessagesDevices } from "../../utils/helper_functions";

import { fleetMetricsStyles } from "../styles/device_metrics_page_styles";
import { useState } from "react";
import React from 'react';
import { useTranslation } from "../../contexts/TranslationContext";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { title } from "process";

const blind_distance = 300;

export const FleetMetrics = (_: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const {t} = useTranslation();
    const totalDevices = useRecoilValue(deviceListStateAtom);
    const data = [
      {
        icon: all_devices_icon,
        value: totalDevices.devices.length,
        title: 'Total Devices Deployed',
      },
      {
        icon: high_warning_icon,
        value: totalDevices.devices.filter(device => device.warning_level === 10).length,
        title: 'High Warning Devices',
      },
      {
        icon: low_battery_icon,
        value: totalDevices.devices.filter(device => device.battery_percentage < 25).length,
        title: 'Low Battery Devices',
      },
      {
        icon: missed_message_icon,
        value: calculateMissingMessagesDevices(totalDevices.devices),
        title: 'Devices With Missed Transmissions',
      },
      {
        icon: weathermode_icon,
        value: totalDevices.devices.filter(device => device.device_weathermode_on === true).length,
        title: 'Devices in Weather Mode',
      },
      // Temporary remove these 2 icons
      // {
      //   icon: high_battery_icon,
      //   value: calculateAverageBattery(totalDevices.devices),
      //   title: 'Average Battery Percentage',
      // },
      // {
      //   icon: fill_percentage_icon,
      //   value: calculateAverageFillPercentage(totalDevices.devices, blind_distance),
      //   title: 'Average Fill Percentage',
      // }
    ]
  
    return (<div data-testid="Fleet Metrics Component" className={css(fleetMetricsStyles.fleet_metrics_wrapper)}>
		<div className={css(fleetMetricsStyles.fleet_metrics_container)}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: 'none', padding: '4px 8px 8px' }}><Typography sx={{fontWeight: 600, fontfamily: '"ubuntu", sans-serif'}}>{t('Summary')}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
            <TableRow>
              <TableCell
                align='left'
                sx={{paddingLeft: 0, paddingRIght: 0, border: 'none', paddingY: 0}}
              >
                <Tooltip title={t(item.title)} placement="left">
                  <img src={item.icon} alt="" className={css(fleetMetricsStyles.fleet_metrics_icon)} />
                </Tooltip>
              </TableCell>
              <TableCell align='right' sx={{border: 'none', paddingLeft: 0, paddingY: 0}}>
                <Typography className={css(fleetMetricsStyles.fleet_metrics_value)}>{item.value}</Typography>
              </TableCell>
            </TableRow>))}
          </TableBody>
        </Table>
		</div>
    </div>)
}
