import '../../../App.css';
import { css } from 'aphrodite';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../../styles/shared_styles.css';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { labelsAtom } from "../../../models/atoms/company_atoms";
import { selectedDeviceStateAtom, filterStateAtom, filteredDeviceListSelector, systemTypesSelector, showManholeIDSelector } from "../../../models/atoms/device_atoms";
import { getRecoil, setRecoil } from "recoil-nexus";
// @ts-ignore
import sort_icon from "../../../assets/sort-icon.svg"
import { deviceListComponentStyles} from '../../styles/device_metrics_page_styles';
import { useState, memo, useEffect } from "react";
import { device_controller } from "../../../controllers/device_controller";
import React from 'react';
import { button_styles, label_styles } from "../../styles/reusable_styles";
import { useTranslation } from '../../../contexts/TranslationContext'; // Import the useTranslation hook
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableSortLabel, Typography } from '@mui/material';
import { primary_background_colour } from '../../styles/style_variables';
import { alphanumeric_comparator } from '../../../utils/helper_functions';
import { DeviceEntry } from './DeviceEntry';

export const DeviceListComponent = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
	const { t } = useTranslation(); // Use the translation hook
	const company = useRecoilValue(labelsAtom);
	const selectedDevice = useRecoilValue(selectedDeviceStateAtom);
	const [filter, setFilterState] = useRecoilState(filterStateAtom);
	const filteredDevices = useRecoilValue(filteredDeviceListSelector);
	const resetFilter = useResetRecoilState(filterStateAtom);
	const [filterDropdown, setFilterDropdown] = useState(false);
	const setSelectedDevice = useSetRecoilState(selectedDeviceStateAtom);
	const [sortDevicesBy, setSortDevicesBy] = useState('device_id');
	const [sortDirection, setSortDirection] = useState<Order>('asc');
	const alertLabels = ["Low battery", "No messages", "High ROC", "Blockage", "High Level", "blockage", "flood", "silt", "infiltration"];
	const systemTypes = useRecoilValue(systemTypesSelector);
	const showManholeID = useRecoilValue(showManholeIDSelector);

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		var aVal: any = a[orderBy];
		var bVal: any = b[orderBy];
		if (typeof aVal === 'string' && typeof bVal === 'string') {
			return alphanumeric_comparator(bVal, aVal);
		}

		if (bVal < aVal) {
			return -1;
		}
		if (bVal > aVal) {
			return 1;
		}
			return 0;
	}
	
	type Order = 'asc' | 'desc';
	
	function getComparator<Key extends keyof any>(
		order: Order,
		orderBy: Key,
	): (a: any, b: any) => number {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function updateOrder(orderBy: string) {
		if (sortDevicesBy !== orderBy) {
		setSortDevicesBy(orderBy);
		setSortDirection('asc');
		} else {
		setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		}
	}

	const headers = [
		{ label: 'Device ID', key: 'device_id', sort: true },
		{ label: 'Fill %', key: 'current_level_percentage', sort: true },
		{ label: 'Labels/Alerts', key: 'labels', sort: false },
		{ label: 'Pinned', key: 'pinned', sort: false },
	]

	if (showManholeID) {
		headers.splice(1, 0, { label: 'Client ID', key: 'comments', sort: true });
	}

	console.log('rerender device list');

	return <article data-testid="Device List Component" style={{
		backgroundColor: primary_background_colour,
		borderRadius: 5,
		padding: '1rem 0rem',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		overflow: 'hidden',
	}}>
		<div className={css(deviceListComponentStyles.search_filter_container)}>
			<input
				className={css(deviceListComponentStyles.search)}
				type="text"
				placeholder={t("Search Devices")}
				value={filter.searchText}
				onChange={(event) => setFilterState({ ...filter, searchText: event.target.value })}
			/>
			<div className={css(deviceListComponentStyles.filter_buttons_container)}>
				<button className={css(button_styles.main_button)} style={{width: '8rem', margin: '0 10px !important', padding: '0.4rem 0.7rem', marginRight: '0.6rem'}} onClick={() => setFilterDropdown(true)}>
					<i className="bi-filter"/>
					{t("Filter")}
				</button>
				<div style={{position: 'absolute'}}>
					{filterDropdown && <div className='dropdown' style={{top: '4.2rem', left: '7.5rem', maxHeight: '11rem', overflow: 'auto'}} onMouseLeave={() => setFilterDropdown(false)} >
						<p className={filter.pinned ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
							setSelectedDevice(null);
							setFilterState({ ...filter, pinned: !filter.pinned });
							await device_controller.get_device_list();
						}}>{t("Pinned")}</p>
						<p className={filter.lowBattery ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
							setSelectedDevice(null);
							setFilterState({ ...filter, lowBattery: !filter.lowBattery });
							await device_controller.get_device_list();
						}}>{t("Low battery")}</p>
						<p className={filter.noMessages ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
							setSelectedDevice(null);
							setFilterState({ ...filter, noMessages: !filter.noMessages });
							await device_controller.get_device_list();
						}}>{t("No messages")}</p>
						<p className={filter.highROC ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
							setSelectedDevice(null);
							setFilterState({ ...filter, highROC: !filter.highROC });
							await device_controller.get_device_list();
						}}>{t("High ROC")}</p>
						<p className={filter.blockage ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
							setSelectedDevice(null);
							setFilterState({ ...filter, blockage: !filter.blockage });
						}}>{t("Blockage")}</p>
						<p className={filter.highWarningLevel ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
							setSelectedDevice(null);
							setFilterState({ ...filter, highWarningLevel: !filter.highWarningLevel });
							await device_controller.get_device_list();
						}}>{t("High Level")}</p>
						{[...systemTypes.filter(label => label), ...company.labels.filter(label => !systemTypes.includes(label) && !alertLabels.includes(label) && label)].map((label) => { 
							return <p key={label} className={filter.labels.includes(label) ? 'applied_dropdown_item' : 'dropdown_item'} onClick={async () => {
								setFilterDropdown(false);
								setSelectedDevice(null);
								if (filter.labels.includes(label)) {
									setFilterState({
									...filter,
									labels: [...filter.labels].filter((item) => item !== label)
									})
								}
								else {
									setFilterState({
									...filter,
									labels: [...filter.labels, label]
									})
								}
								await device_controller.get_device_list();
							}}>
								{t(label)}
							</p>
						})}
					</div>}
				</div>
				<button className={css(button_styles.main_button)} style={{width: '8rem', margin: '0rem 0.5rem !important', padding: '0.4rem 0.7rem'}} onClick={async () => { 
					resetFilter();
					await device_controller.get_device_list();
					setSelectedDevice(null);
				}}>{t("Clear Filter")}</button>
			</div>
		</div>
			
		<TableContainer>
			<Table stickyHeader>
			<TableHead>
				<TableRow>
				{headers.map((item) => (
				<TableCell align='center' onClick={() => updateOrder(item.key)} sortDirection={sortDevicesBy === item.key ? sortDirection : false}>
					<TableSortLabel
					active={item.sort && sortDevicesBy === item.key}
					hideSortIcon={!item.sort}
					direction={sortDirection}
					onClick={() => updateOrder(item.key)}
					IconComponent={
						() => <img src={sort_icon} alt="sort icon" className={css(deviceListComponentStyles.sort_icon)} />
					}
					>
					<Typography sx={{fontWeight: 600, fontFamily: '"Ubuntu", sans-serif'}}>{t(item.label)}</Typography>
					</TableSortLabel>
				</TableCell>
				))}
				</TableRow>
			</TableHead>
			<TableBody>
				{filteredDevices.devices.slice().sort(getComparator(sortDirection, sortDevicesBy)).map((device) => {
				return <DeviceEntry device={device} selectedDevice={selectedDevice} alertLabels={alertLabels} />
				})}
			</TableBody>
			</Table>
		</TableContainer>
	</article>
};
